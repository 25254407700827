import React from "react"
import { graphql, Link } from "gatsby"

import SEO from "../components/seo"

import {
  Icon,
  Container,
  Section,
  Main,
  Header,
  Footer,
} from "../components/Lib"
import { BlogPosts } from "../pages/blog"
import { toPersianNumber, concatPeople } from "../utils/helpers"

const Pagination = ({ slug, currentPage, numberOfPages }) => {
  return (
    <div className="pagination">
      {currentPage + 1 <= numberOfPages && (
        <Link
          to={`/blog/tag/${slug}/${currentPage + 1}`}
          className="pagination__icon navigator"
        >
          <Icon name="arrow_right" />
        </Link>
      )}
      <div className="pagination__current">
        صفحه {toPersianNumber(currentPage)} از {toPersianNumber(numberOfPages)}
      </div>
      {currentPage - 1 > 0 && (
        <Link
          to={`/blog/tag/${slug}/${currentPage - 1}`}
          className="pagination__icon navigator"
        >
          <Icon name="arrow_left" />
        </Link>
      )}
    </div>
  )
}

const tag = ({ data, pageContext }) => {
  const posts = data.posts.edges
  const people = data.people.edges
  return (
    <Container>
      <SEO
        title={`نوشته های مرتبط به ${concatPeople(people)}`}
        description={`داستان و نوشته های درباره ${concatPeople(
          people
        )} را دربلاگ بایوکست بخوانید`}
        keywords={posts[0].node.keyword.map(item => item.title)}
      />
      <Header className="header--standalone header--blog" />
      <Main>
        <Section className="all_posts">
          <h1 className="all_posts__title">
            نوشته‌های مرتبط به {concatPeople(people)}
          </h1>
          <div className="inner_wrapper">
            <BlogPosts posts={posts} />
            <Pagination
              slug={pageContext.slug}
              currentPage={pageContext.currentPage}
              numberOfPages={pageContext.numberOfPages}
            />
          </div>
        </Section>
      </Main>
      <Footer beforeColor="white" />
    </Container>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!, $people: [Int]!) {
    people: allStrapiPerson(filter: { strapiId: { in: $people } }) {
      edges {
        node {
          shownName
          id
        }
      }
    }
    posts: allStrapiPost(
      filter: { people: { elemMatch: { id: { in: $people } } } }
      sort: { fields: created_at, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          created_at
          description
          content
          author {
            firstName
            lastName
          }
          keyword {
            title
          }
          mainImage {
            childImageSharp {
              fluid(quality: 70) {
                src
              }
            }
          }
          category {
            name
          }
          fields {
            readingTime
            slug
          }
        }
      }
    }
  }
`

export default tag
